import React, { useState } from "react";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import { NavLink } from "react-router-dom";
import MenuItems from "../resources/AdminUI/MenuItems";
import { LoginStore } from "../store/LoginStore";

interface IProps {
  logout?: () => void;
}

const Nav: React.FC<IProps> = ({ logout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="mobile-menu-icon" onClick={toggleMenu}>
        <MenuOutlined />
      </div>
      <div
        className={`overlay ${isMenuOpen ? "open" : ""}`}
        onClick={closeMenu}
      />
      <section className={`nav-container ${isMenuOpen ? "open" : ""}`}>
        <div className="close-menu-icon" onClick={toggleMenu}>
          <CloseOutlined />
        </div>
        <div className="logo-container">
          <img
            src="/assets/images/logo_app.svg"
            className="logo"
            alt="App Logo"
          />
        </div>

        <div className="account-info">
          <div className="initials">{MenuItems.Resources.pi}</div>
          <div>
            <div className="dashboard-user">
              {MenuItems.Resources.pluriaInternational}
            </div>
            <div className="dashboard-name">
              {MenuItems.Resources.companyAdmin}
            </div>
          </div>
        </div>

        <div className="menu">
          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.general}
            </div>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.Dashboard}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">widgets</div>
              <div className="title">{MenuItems.Resources.dashboard}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.Employees}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">group</div>
              <div className="title">{MenuItems.Resources.employees}</div>
            </NavLink>
          </div>

          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.activity}
            </div>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.DesksLogs}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">calendar_month</div>
              <div className="title">{MenuItems.Resources.desksLogs}</div>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-item-container" : "menu-item-container"
              }
              to={ApplicationPaths.RoomsLogs}
              onClick={closeMenu}
            >
              <div className="material-symbols-outlined">calendar_month</div>
              <div className="title">{MenuItems.Resources.roomsLogs}</div>
            </NavLink>
          </div>

          <div className="menu-section">
            <div className="menu-section-title">
              {MenuItems.Resources.settings}
            </div>
            <NavLink
              className="menu-item-container"
              to={ApplicationPaths.EmptyPath}
              onClick={logout}
            >
              <div className="material-symbols-outlined">logout</div>
              <div className="title">{MenuItems.Resources.logout}</div>
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect((state: ApplicationState) => ({}), {
  logout: LoginStore.actionCreators.logout,
})(Nav);
