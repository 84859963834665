import { useCallback, useEffect } from "react";
import { UserStateFilterOptions } from "../../enums/Users/UserStateFilterOptions";
import { UserStates } from "../../enums/Users/UserStates";
import UserStateTypes from "../../resources/AdminUI/Users/UserStateTypes";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import {
  String_Format,
  getPromiseFromAction,
  firstLetterToLowerCase,
} from "../../utils/utils";
import {
  searchByUserNameTemplate,
  searchByIdTemplate,
  deletedStateFilterOptionTemplate,
  deactivatedStateFilterOptionTemplate,
  notCompletedStateFilterOptionTemplate,
  activeAndCompletedStateFilterOptionTemplate,
} from "./constants";

export const useUsersGridPage = ({
  searchedTerm,
  selectedStateId,
  setFilter,
  changeCurrentPage,
  reload,
  setSearchedTerm,
  setSelectedStateId,
}) => {
  const rebuildOdataAndGetData = useCallback(() => {
    let filters = [];

    if (searchedTerm) {
      let searchFilter = String_Format(searchByUserNameTemplate, searchedTerm);

      let pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(searchedTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(searchByIdTemplate, searchedTerm)
        );
      }

      filters.push(`(${searchFilter})`);
    }

    if (
      selectedStateId !== "0" &&
      selectedStateId !== UserStateFilterOptions.AllUserStates.toString()
    ) {
      switch (selectedStateId) {
        case UserStateFilterOptions.Deleted.toString():
          filters.push(
            String_Format(deletedStateFilterOptionTemplate, UserStates.Deleted)
          );
          break;
        case UserStateFilterOptions.Deactivated.toString():
          filters.push(
            String_Format(
              deactivatedStateFilterOptionTemplate,
              UserStates.Deactivated
            )
          );
          break;
        case UserStateFilterOptions.NotCompleted.toString():
          filters.push(
            String_Format(
              notCompletedStateFilterOptionTemplate,
              UserStates.Active
            )
          );
          break;
        case UserStateFilterOptions.ActiveAndAccountCompleted.toString():
          filters.push(
            String_Format(
              activeAndCompletedStateFilterOptionTemplate,
              UserStates.Active
            )
          );
          break;
      }
    }

    let oDataFilter = filters.join(" and ");

    setFilter(oDataFilter);
    changeCurrentPage(1);
    getPromiseFromAction(reload()).then(() => changeCurrentPage(1));
  }, [searchedTerm, selectedStateId, setFilter, changeCurrentPage, reload]);

  const handleOnSearchFilterChange = useCallback(
    (e) => {
      if (searchedTerm !== e.currentTarget.value) {
        getPromiseFromAction(
          setSearchedTerm(e.currentTarget.value.toString())
        ).then(() => {
          rebuildOdataAndGetData();
        });
      }
    },
    [searchedTerm, setSearchedTerm, rebuildOdataAndGetData]
  );

  const getUserStates = useCallback(() => {
    let options = [];

    let keys = Object.keys(UserStateFilterOptions).filter(
      (k) => typeof UserStateFilterOptions[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = UserStateFilterOptions[key as any];
      options.push({
        value: value.toString(),
        label: UserStateTypes.Resources[firstLetterToLowerCase(key)],
      });
    });

    return options;
  }, []);

  const handleOnStateFilterChange = useCallback(
    (value: string, option: any) => {
      getPromiseFromAction(setSelectedStateId(value)).then(() => {
        rebuildOdataAndGetData();
      });
    },
    [setSelectedStateId, rebuildOdataAndGetData]
  );

  useEffect(() => {
    rebuildOdataAndGetData();
  }, []);

  return {
    handleOnSearchFilterChange,
    getUserStates,
    handleOnStateFilterChange,
  };
};
