import { ServiceBase } from "./ServiceBase";
import Result from "../models/Common/Result";
import { AppSettings } from "../utils/settings";
import { ICompanyWorkspacesWithActiveBookings } from "../models/Dashboard/Interfaces/ICompanyWorkspacesWithActiveBookings";
import { ILocationSelectListItem } from "../models/Dashboard/Interfaces/ILocationSelectListItem";

export default class DashboardService extends ServiceBase {
  static getCompanyStatistics = (): Promise<Result<any>> => {
    try {
      var result = DashboardService.requestJsonFetch<any>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/dashboard/statistics`,
      });
    } catch (error) {}

    return result;
  };

  static getCompanyActiveBookings = (
    monthsValue
  ): Promise<Result<ICompanyWorkspacesWithActiveBookings[]>> => {
    try {
      var result = DashboardService.requestJsonFetch<any>({
        method: "GET",
        url: `${AppSettings.CommonSettings.ApiUrl}/dashboard/active-bookings?pastMonths=${monthsValue}`,
      });
    } catch (error) {}

    return result;
  };

  static getAllCities = (): Promise<Result<ILocationSelectListItem[]>> => {
    try {
      var result = DashboardService.requestJsonFetch<ILocationSelectListItem[]>(
        {
          method: "GET",
          url: `${AppSettings.CommonSettings.ApiUrl}/dashboard/cities`,
        }
      );
      return result;
    } catch (error) {}

    return new Promise<Result<ILocationSelectListItem[]>>(null);
  };
}
