import { FormFile } from "../models/Common/FormFile";
import { AppThunkActionAsync } from "../store/index";
import ErrorMessages from "../resources/Common/ErrorMessages";
import RegularExpressions from "../resources/Common/RegularExpressions";

export function nameof<T = any>(f: (x: T) => any): keyof T & string {
    const p = new Proxy({}, {
        get: (_, key) => key
    })
    return f(p as T);
}

export function getPromiseFromAction<T, V>(asyncActionCreator: AppThunkActionAsync<T, V>): Promise<V> {
    return (asyncActionCreator as any) as Promise<V>;
}

export function MapObject(source: any, destination: any) {
    var reducedSource = pick(source, Object.keys(destination));
    Object.getOwnPropertyNames(reducedSource).forEach(p => {
        if (reducedSource[p] == null && destination[p] == null) {
            destination[p] = null;
        }
        else {
            let propertyPrototype = reducedSource[p]
                ? Object.getPrototypeOf(reducedSource[p])
                : Object.getPrototypeOf({});

            switch (propertyPrototype) {
                case Array.prototype:
                    destination[p] = Array.from(reducedSource[p]);
                    break;
                case FormFile.prototype:
                    destination[p].fileUrl = reducedSource[p] ? reducedSource[p].fileUrl : '';
                    break;
                case Number.prototype:
                    destination[p] = reducedSource[p];
                    break;
                default:
                    if (reducedSource[p] != null) {
                        destination[p] = new (propertyPrototype).constructor(reducedSource[p]);
                    }
            }
        }
    });
    return destination;
}

function pick(obj, keys) {
    return keys.map(k => k in obj ? { [k]: obj[k] } : {})
        .reduce((res, o) => Object.assign(res, o), {});
}

export function GetRealPropertiesOfObject(source: any, base: any) {
    return Object.keys(source).filter(p => Object.keys(base).indexOf(p) < 0);
}

export function String_Format(format: string, ...args: any[]) {
    return format.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
};

export function firstLetterToLowerCase(name: string): string {
    return name ? name[0].toLowerCase() + name.slice(1) : name;
}

export function getServerSideErrors(serverErrors: any) {
    var errors = serverErrors.errors == undefined && serverErrors.value == null ? ErrorMessages.Resources.generalErrorMessage
        : serverErrors.hasErrors ? Object.keys(serverErrors.errors).map(key => {
            return serverErrors.errors[key];
        }).join(", ") : null;

    return errors;
}


export function isEmail(email: string) {
    var pattern1 = RegularExpressions.Resources.emailValidation;
    return RegExp(pattern1).test(email);
}

export function getArrayAsCommaSeparatedString(array: any[]) {
    if (array?.length) {
        return array.filter(e => !!e).join(', ');
    } else {
        return '';
    }
}
