import React from "react";
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { ICompanyWorkspacesWithActiveBookings } from "../../models/Dashboard/Interfaces/ICompanyWorkspacesWithActiveBookings";
import { BookingLogTypes } from "../../enums/Common/BookingLogTypes";

interface IMapMarkerProps extends ICompanyWorkspacesWithActiveBookings {
  onSelectedLocationChange: (event, id, lat, lng) => void;
}

const MapMarker = (props: IMapMarkerProps) => {
  const [markerRef] = useAdvancedMarkerRef();

  const handleInfoWindowClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.onSelectedLocationChange(
      event,
      props.workspaceId,
      props.lat,
      props.lng
    );
  };

  const hotdeskCheckins = props.activeBookings.filter(
    (b) => b.featureId === BookingLogTypes.HotdeskLogs
  ).length;

  const roomBookings = props.activeBookings.filter(
    (b) => b.featureId === BookingLogTypes.RoomLogs
  ).length;

  return (
    <AdvancedMarker
      ref={markerRef}
      onClick={handleInfoWindowClick}
      position={{ lat: props.lat, lng: props.lng }}
      title={props.workspaceName}
    >
      <div
        id={`info-content-${props.workspaceId}`}
        onClick={handleInfoWindowClick}
        className="marker_tooltip"
      >
        <div>{props.workspaceName}</div>
      </div>
    </AdvancedMarker>
  );
};

export default MapMarker;
