import { useCallback, useEffect } from "react";

import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import HotdeskBookingStatusTypes from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingStatusTypes";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import HotdeskBookingsService from "../../services/HotdeskBookingsService";
import {
  String_Format,
  getPromiseFromAction,
  firstLetterToLowerCase,
} from "../../utils/utils";
import { prepareCsvContent, downloadCsv } from "./helpers";

export interface IHotdeskBookingsGridPageProps {
  filters: string;
  searchTerm: string;
  singleSelectedStatus: string;
  startDateSearchTemplate: number;
  endDateSearchTemplate: number;
  initialize: any;
  reload: any;
  changeCurrentPage: any;
  setFilters: any;
  setSearchTerm: any;
  setSingleSelectedStatus: any;
  setStartDateSearchTemplateAndEndDateSearchTemplate: any;
}

export const useHotdeskBookingsGridPage = (
  props: IHotdeskBookingsGridPageProps
) => {
  const {
    filters,
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
    initialize,
    reload,
    changeCurrentPage,
    setFilters,
    setSearchTerm,
    setSingleSelectedStatus,
    setStartDateSearchTemplateAndEndDateSearchTemplate,
  } = props;

  const nameSearchTemplate = `contains(concat(concat(tolower(UserFirstName), ' '), tolower(UserLastName)), tolower('{0}')) or contains(tolower(WorkspaceName), tolower('{0}'))`;
  const idSearchTemplate = ` or Id eq {0} or contains(tolower(BookingGroupId), tolower('{0}'))`;
  const statusFilterTemplate = `StatusId eq {0}`;
  const startDateFilterTemplate = `UnixBookingAtWithTimezone eq {0}`;
  const startAndEndDateFilterTemplate = `UnixBookingAtWithTimezone ge {0} and UnixBookingAtWithTimezone le {1}`;

  const rebuildODataAndGetData = useCallback(() => {
    const filtersArray = [];
    if (searchTerm) {
      let searchFilter = String_Format(nameSearchTemplate, searchTerm);
      if (RegExp(RegularExpressions.Resources.onlyNumbers).test(searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(idSearchTemplate, searchTerm)
        );
      }
      filtersArray.push(`(${searchFilter})`);
    }

    if (
      singleSelectedStatus &&
      singleSelectedStatus != DefaultFilterValues.All.toString()
    ) {
      filtersArray.push(
        `(${String_Format(statusFilterTemplate, singleSelectedStatus)})`
      );
    }

    if (startDateSearchTemplate && endDateSearchTemplate) {
      if (startDateSearchTemplate == endDateSearchTemplate) {
        filtersArray.push(
          `(${String_Format(startDateFilterTemplate, startDateSearchTemplate)})`
        );
      } else {
        filtersArray.push(
          `(${String_Format(
            startAndEndDateFilterTemplate,
            startDateSearchTemplate,
            endDateSearchTemplate
          )})`
        );
      }
    }

    const oDataFilter = filtersArray.join(" and ");

    setFilters(oDataFilter);
    changeCurrentPage(1);
    getPromiseFromAction(reload()).then(() => changeCurrentPage(1));
  }, [
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
    setFilters,
    changeCurrentPage,
    reload,
  ]);

  const onSearchFilterChange = useCallback(
    (e) => {
      const newSearchTerm = e.currentTarget.value;
      if (searchTerm !== newSearchTerm) {
        setSearchTerm(newSearchTerm);
      }
    },
    [searchTerm, setSearchTerm]
  );

  const onStatusFilterChange = useCallback(
    (value) => {
      setSingleSelectedStatus(value);
    },
    [setSingleSelectedStatus]
  );

  const onDateChange = useCallback(
    (dates, dateStrings) => {
      if (dateStrings[0] === dateStrings[1]) {
        const startDate = dateStrings[0].split("-").reverse().join("-");
        const startDateSearchTemplate = Math.floor(
          new Date(startDate).getTime() / 1000
        );
        setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          startDateSearchTemplate
        );
      } else {
        const startDate = dateStrings[0].split("-").reverse().join("-");
        const endDate = dateStrings[1].split("-").reverse().join("-");
        const startDateSearchTemplate = Math.floor(
          new Date(startDate).getTime() / 1000
        );
        const endDateSearchTemplate = Math.floor(
          new Date(endDate).getTime() / 1000
        );
        setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          endDateSearchTemplate
        );
      }
    },
    [setStartDateSearchTemplateAndEndDateSearchTemplate]
  );

  const getAllHodeskStatuses = useCallback(() => {
    return Object.keys(HotdeskBookingStatuses)
      .filter((key) => typeof HotdeskBookingStatuses[key as any] === "number")
      .map((key) => ({
        value: HotdeskBookingStatuses[key as any].toString(),
        label: HotdeskBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      }));
  }, []);

  const handleExportLogs = useCallback(async () => {
    const res = await HotdeskBookingsService.getHotdeskBookingsForExport(
      filters
    );
    const entities = res.value ? res.value.entities : [];
    const csvContent = prepareCsvContent(entities);
    downloadCsv(csvContent);
  }, [filters]);

  useEffect(() => {
    initialize(15, filters);
  }, []);

  useEffect(() => {
    rebuildODataAndGetData();
  }, [
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
  ]);

  return {
    onSearchFilterChange,
    onStatusFilterChange,
    onDateChange,
    getAllHodeskStatuses,
    exportLogs: handleExportLogs,
  };
};
