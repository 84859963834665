import React from "react";
import { APIProvider } from "@vis.gl/react-google-maps";

export const MAP_JS_ID = "pluriaMap";
export const MAP_CONFIG_ID = "53ffa5d283134f61";

interface MapViewerWrapperProps {
  children: React.ReactNode;
}

const MapViewerWrapper: React.FC<MapViewerWrapperProps> = ({ children }) => {
  return (
    <div
      className="map_container"
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        borderRadius: "20px",
      }}
    >
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["marker"]}
      >
        {children}
      </APIProvider>
    </div>
  );
};

export default MapViewerWrapper;
