import React from "react";
import Helmet from "react-helmet";
import { Tooltip } from "antd";

import Dashboard from "../../resources/AdminUI/CompanyDashboard/Dashboard";
import MapViewer from "../../components/Shared/MapViewer";
import MapViewerWrapper from "../../components/Shared/MapViewerWrapper";
import CheckinsContentCard from "../../components/Dashboard/CheckinsContentCard";
import AccountsDetailsContentCard from "../../components/Dashboard/AccountsDetailsContentCard";
import { String_Format } from "../../utils/utils";
import { useDashboardPage } from "../../hooks/Dashboard/useDashboardPage";
import Loading from "../../components/Shared/Loading";
import TabsHeader from "../../components/Tabs/TabsHeader";

const DashboardPage: React.FC = () => {
  const {
    statistics,
    loadingActiveBookings,
    workspacesWithActiveBookings,
    citiesByActiveBookings,
    monthTabs,
    selectedMonthTab,
    selectedCoords,
    resetMapFilters,
    setResetMapFilters,
    handleTabClick,
    getWorkspacesForFilter,
    getDefaultCity,
    setSelectedCoords,
    handleListItemClick,
  } = useDashboardPage();

  return (
    <div>
      <Helmet title={Dashboard.Resources.dashboardTitle}></Helmet>
      <div className="dashboard">
        <div className="page_title">
          {String_Format(Dashboard.Resources.helloHeader, statistics.name)}
        </div>
        <div className="main_container">
          <div className="column_left">
            <TabsHeader
              tabs={monthTabs}
              onTabClick={handleTabClick}
              activeTabId={selectedMonthTab}
              className={"section-table-tabs"}
            />
            <div className="widget_container">
              <AccountsDetailsContentCard
                activeUsers={statistics.activeUsers}
                totalUsers={statistics.totalUsers}
              />
            </div>
            <div className="widget_container">
              <CheckinsContentCard
                currentCheckIns={statistics?.currentCheckIns}
                totalCheckIns={statistics?.totalCheckIns}
                uniqueUsersCheckins={statistics?.uniqueUsersCheckins}
                uniqueUsersCurrCheckins={statistics?.uniqueUsersCurrCheckins}
              />
            </div>
            <div className="widget_container full_height">
              <div className="widget_title_container">
                <div
                  className="widget_title"
                  dangerouslySetInnerHTML={{
                    __html: Dashboard.Resources.preferredWorkingSpaces,
                  }}
                ></div>
                <Tooltip title={Dashboard.Resources.totalCheckinsTooltip}>
                  <div className="ic_info">
                    <span className="material-symbols-outlined">info_i</span>
                  </div>
                </Tooltip>
              </div>
              <div className="preferred_spaces_list">
                {workspacesWithActiveBookings.length == 0 ? (
                  <div className="section_subtitle">
                    {Dashboard.Resources.noPreferredSpacesYet}
                  </div>
                ) : (
                  <div>
                    {workspacesWithActiveBookings.map((space, index) => {
                      return (
                        <div
                          className="list_item"
                          key={space.workspaceId}
                          onClick={() =>
                            handleListItemClick(space.lat, space.lng)
                          }
                        >
                          <div className="list_item_left">
                            <div className="item_position">{index + 1}.</div>
                            <div className="item_space_name">
                              {space.workspaceName}
                            </div>
                          </div>
                          <div className="list_item_right">
                            <div className="item_visits_no dashboard_section_preferred_no_visits">
                              {space.visits}{" "}
                              {space.visits == 1
                                ? Dashboard.Resources.visitLabel
                                : Dashboard.Resources.visitsLabel}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="column_right">
            <div className="widget_container">
              {citiesByActiveBookings && workspacesWithActiveBookings && (
                <MapViewerWrapper>
                  <MapViewer
                    defaultCity={getDefaultCity()}
                    cities={citiesByActiveBookings}
                    locations={workspacesWithActiveBookings}
                    workspaces={getWorkspacesForFilter()}
                    selectedCoords={selectedCoords}
                    resetMapFilters={resetMapFilters}
                    setSelectedCoords={setSelectedCoords}
                    onFiltersReset={() => setResetMapFilters(false)}
                  ></MapViewer>
                </MapViewerWrapper>
              )}
              {loadingActiveBookings && (
                <div>
                  <Loading />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
