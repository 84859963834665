import { DatePicker, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FC } from "react";

import { dateFormat } from "../../hooks/RoomBookingsGridPage/constants";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";

const { RangePicker } = DatePicker;

export interface IFilterBarProps {
  searchTerm: string;
  singleSelectedStatus: string;
  startDateSearchTemplate: number | null;
  endDateSearchTemplate: number | null;
  onStatusFilterChange: (value: string, option: any) => void;
  onDateChange: (
    dates: [Dayjs | null, Dayjs | null],
    dateStrings: [string, string]
  ) => void;
  onSearchFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getAllRoomStatuses: () => { label: string; value: string }[];
}

export const FilterBar: FC<IFilterBarProps> = (props) => {
  const {
    searchTerm,
    onSearchFilterChange,
    getAllRoomStatuses,
    singleSelectedStatus,
    onStatusFilterChange,
    startDateSearchTemplate,
    endDateSearchTemplate,
    onDateChange,
  } = props;
  return (
    <div className="filter-bar">
      <div className="filter-search">
        <div className="input-group">
          <span className="icon material-symbols-outlined">search</span>
          <input
            type="text"
            className="form-control"
            placeholder={
              RoomBookingsGrid.Resources.searchByLogIdUserNameOrSpaceName
            }
            onChange={onSearchFilterChange}
            value={searchTerm}
          />
        </div>
      </div>

      <div className="filter-item">
        <div className="input-group">
          <Select
            options={getAllRoomStatuses()}
            onChange={onStatusFilterChange}
            value={singleSelectedStatus}
          ></Select>
        </div>
      </div>

      <RangePicker
        value={[
          startDateSearchTemplate
            ? dayjs.unix(startDateSearchTemplate)
            : (startDateSearchTemplate as unknown as Dayjs),
          endDateSearchTemplate
            ? dayjs.unix(endDateSearchTemplate)
            : (endDateSearchTemplate as unknown as Dayjs),
        ]}
        format={dateFormat}
        onChange={onDateChange}
      />
    </div>
  );
};
