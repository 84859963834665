export const searchByUserNameTemplate = `contains(concat(concat(tolower(firstName), ' '), tolower(lastName)), tolower('{0}')) or contains(tolower(email), tolower('{0}'))`;
export const searchByIdTemplate = ` or id eq {0}`;

export const deletedStateFilterOptionTemplate = "status eq {0}";
export const deactivatedStateFilterOptionTemplate = "status eq {0}";
export const notCompletedStateFilterOptionTemplate =
  "status eq {0} and onBoardingComplete eq false";
export const activeAndCompletedStateFilterOptionTemplate =
  "status eq {0} and onBoardingComplete eq true";

export const hiddenColumnsForUsersTable = [
  "teamName",
  "deactivated",
  "id",
  "lastName",
  "phone",
  "status",
  "onBoardingComplete",
  "companyId",
  "isSmsPreferredChannel",
  "isEmailPreferredChannel",
  "totalCheckins",
  "isBookingManager",
  "marketingPreferences",
  "teamId",
  "changeTeamAction",
];
export const hotdeskBookingsTableHiddenColumns = [
  "createdAt",
  "unixBookingAtWithTimezone",
  "bookingAt",
  "checkinAt",
  "checkoutAt",
  "cancelledAt",
  "expiresAt",
  "statusName",
  "timeSpent",
  "timezone",
  "isFullDay",
  "isAutomatedCheckout",
  "userFirstName",
  "userLastName",
  "userPhone",
  "workspaceCategoryId",
  "workspaceCategoryName",
  "workspaceCityName",
];

export const roomBookingsTableHiddenColumns = [
  "startsAt",
  "unixStartsAtWithTimezone",
  "confirmedAt",
  "createdAt",
  "endsAt",
  "cancelledAt",
  "expiresAt",
  "rejectedAt",
  "statusName",
  "bookingTypeName",
  "bookingAmount",
  "userId",
  "userFirstName",
  "userLastName",
  "userEmail",
  "userPhone",
  "workspaceCityName",
  "workspaceCategoryId",
  "workspaceCategoryName",
  "currency",
  "timezone",
];
