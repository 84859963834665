import { LocalDateTime } from "@js-joda/core";
import { useEffect, useCallback } from "react";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingStatusTypes from "../../resources/AdminUI/RoomBookings/RoomBookingStatusTypes";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import RegularExpressions from "../../resources/Common/RegularExpressions";
import RoomBookingsService from "../../services/RoomBookingsService";
import {
  String_Format,
  getPromiseFromAction,
  firstLetterToLowerCase,
} from "../../utils/utils";
import {
  nameSearchTemplate,
  idSearchTemplate,
  statusFilterTemplate,
  startDateFilterTemplate,
  startAndEndDateFilterTemplate,
} from "./constants";

export const useRoomBookingsGridPage = (props) => {
  const {
    filters,
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
    navigate,
    initialize,
    reload,
    changeCurrentPage,
    setFilters,
    setSearchTerm,
    setSingleSelectedStatus,
    setStartDateSearchTemplateAndEndDateSearchTemplate,
  } = props;

  const rebuildODataAndGetData = useCallback(() => {
    const filtersArray = [];
    if (searchTerm) {
      let searchFilter = String_Format(nameSearchTemplate, searchTerm);
      const pattern = RegularExpressions.Resources.onlyNumbers;
      if (RegExp(pattern).test(searchTerm)) {
        searchFilter = searchFilter.concat(
          String_Format(idSearchTemplate, searchTerm)
        );
      }
      filtersArray.push(`(${searchFilter})`);
    }

    if (
      singleSelectedStatus &&
      singleSelectedStatus !== DefaultFilterValues.All.toString()
    ) {
      filtersArray.push(
        `(${String_Format(statusFilterTemplate, singleSelectedStatus)})`
      );
    }

    if (startDateSearchTemplate !== null && endDateSearchTemplate !== null) {
      if (startDateSearchTemplate === endDateSearchTemplate) {
        const startDateFilter = String_Format(
          startDateFilterTemplate,
          startDateSearchTemplate
        );
        filtersArray.push(`(${startDateFilter})`);
      } else {
        const startAndEndDateFilter = String_Format(
          startAndEndDateFilterTemplate,
          startDateSearchTemplate,
          endDateSearchTemplate
        );
        filtersArray.push(`(${startAndEndDateFilter})`);
      }
    }

    const oDataFilter = filtersArray.join(" and ");
    setFilters(oDataFilter);
    changeCurrentPage(1);
    getPromiseFromAction(reload()).then(() => changeCurrentPage(1));
  }, [
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
    setFilters,
    changeCurrentPage,
    reload,
  ]);

  const onSearchFilterChange = useCallback(
    (e) => {
      const newSearchTerm = e.currentTarget.value;
      if (newSearchTerm !== searchTerm) {
        setSearchTerm(newSearchTerm);
      }
    },
    [searchTerm, setSearchTerm]
  );

  const onStatusFilterChange = useCallback(
    (value) => {
      setSingleSelectedStatus(value);
    },
    [setSingleSelectedStatus]
  );

  const onDateChange = useCallback(
    (dates, dateStrings) => {
      if (dateStrings[0] === dateStrings[1]) {
        const startDate = dateStrings[0].split("-").reverse().join("-");
        const startDateSearchTemplate = parseInt(
          (new Date(startDate).getTime() / 1000).toFixed(0)
        );
        setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          startDateSearchTemplate
        );
      } else {
        const startDate = dateStrings[0].split("-").reverse().join("-");
        const endDate = dateStrings[1].split("-").reverse().join("-");
        const startDateSearchTemplate = parseInt(
          (new Date(startDate).getTime() / 1000).toFixed(0)
        );
        const endDateSearchTemplate = parseInt(
          (new Date(endDate).getTime() / 1000).toFixed(0)
        );
        setStartDateSearchTemplateAndEndDateSearchTemplate(
          startDateSearchTemplate,
          endDateSearchTemplate
        );
      }
    },
    [setStartDateSearchTemplateAndEndDateSearchTemplate]
  );

  const getAllRoomStatuses = useCallback(() => {
    const options = [];
    const keys = Object.keys(RoomBookingStatuses).filter(
      (k) => typeof RoomBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      const value = RoomBookingStatuses[key as any].toString();
      options.push({
        value: value,
        label: RoomBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  }, []);

  const exportLogs = useCallback(async () => {
    const res = await RoomBookingsService.getRoomBookingsForExport(filters);
    const entities = res.value
      ? res.value.entities
          .map((e) =>
            Object.entries(e)
              .filter(
                ([key]) =>
                  key !== "unixStartsAtWithTimezone" &&
                  key !== "timezone" &&
                  key !== "statusId" &&
                  key !== "workspaceCategoryId" &&
                  key !== "bookingTypeId"
              )
              .map(([key, value]) =>
                typeof value === "string" ? value.replaceAll(",", " ") : value
              )
              .join(",")
          )
          .join("\n")
      : "";
    const csvContent =
      RoomBookingsGrid.Resources.companyDashboardExportRoomBookingLogsHeader +
      "\n" +
      entities;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      RoomBookingsGrid.Resources.roomsLogs +
        "_" +
        LocalDateTime.now() +
        RoomBookingsGrid.Resources.csvExtension
    );
    document.body.appendChild(link);

    link.click();
  }, [filters]);

  useEffect(() => {
    rebuildODataAndGetData();
  }, [
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
  ]);

  useEffect(() => {
    initialize(15, filters);
  }, []);

  return {
    onSearchFilterChange,
    onStatusFilterChange,
    onDateChange,
    getAllRoomStatuses,
    exportLogs,
  };
};
