import { useState, useEffect, useCallback } from "react";
import { useDashboardData } from "./useDashboardData";
import General from "../../resources/AdminUI/General";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { ILocationSelectListItem } from "../../models/Dashboard/Interfaces/ILocationSelectListItem";
import { String_Format } from "../../utils/utils";
import Dashboard from "../../resources/AdminUI/CompanyDashboard/Dashboard";

export const useDashboardPage = () => {
  const [citiesByActiveBookings, setCitiesByActiveBookings] = useState<
    ILocationSelectListItem[]
  >([]);
  const [selectedCoords, setSelectedCoords] = useState({
    lat: null,
    lng: null,
  });
  const [resetMapFilters, setResetMapFilters] = useState(false);
  const [selectedMonthTab, setSelectedMonthTab] = useState<string>("1");

  const monthTabs = [
    {
      tabId: "1",
      title: String_Format(Dashboard.Resources.monthData, 1),
      class: "",
    },
    {
      tabId: "6",
      title: String_Format(Dashboard.Resources.monthData, 6),
      class: "",
    },
  ];
  const {
    statistics,
    loadingStatistics,
    loadingActiveBookings,
    loadingCities,
    workspacesWithActiveBookings,
    cities,
    errorActiveBookings,
    errorCities,
  } = useDashboardData(parseInt(selectedMonthTab));

  const handleTabClick = (tabId: string) => {
    setSelectedMonthTab(tabId);
  };

  const handleListItemClick = useCallback((lat, lng) => {
    setResetMapFilters(true);
    setSelectedCoords({ lat, lng });
  }, []);

  useEffect(() => {
    if (
      !cities ||
      !workspacesWithActiveBookings ||
      errorCities ||
      errorActiveBookings ||
      loadingCities ||
      loadingActiveBookings
    )
      return;

    const filteredCities = cities.filter((city) =>
      workspacesWithActiveBookings.some(
        (workspace) => workspace.cityId.toString() === city.value
      )
    );

    filteredCities.unshift({
      label: General.Resources.allCities,
      value: DefaultFilterValues.All.toString(),
      lat: 0,
      lng: 0,
    });

    setCitiesByActiveBookings(filteredCities);
  }, [
    cities,
    workspacesWithActiveBookings,
    errorActiveBookings,
    errorCities,
    loadingCities,
    loadingActiveBookings,
  ]);

  const getWorkspacesForFilter = useCallback(() => {
    const workspaces = workspacesWithActiveBookings.map((w) => ({
      label: w.workspaceName,
      value: w.workspaceId.toString(),
      lat: w.lat,
      lng: w.lng,
    }));

    workspaces.sort((a, b) => {
      const greaterLabelA = a.label > b.label ? 1 : 0;
      return a.label < b.label ? -1 : greaterLabelA;
    });

    workspaces.unshift({
      label: General.Resources.allWorkspaces,
      value: DefaultFilterValues.All.toString(),
      lat: 0,
      lng: 0,
    });

    return workspaces;
  }, [workspacesWithActiveBookings]);

  const getDefaultCity = useCallback(() => {
    const result = workspacesWithActiveBookings.reduce((r, a) => {
      if (r[a.cityId]) {
        r[a.cityId] += 1;
      } else {
        r[a.cityId] = 1;
      }
      return r;
    }, Object.create(null));

    let max = 0;
    let cityId;

    Object.keys(result).forEach((r) => {
      if (result[r] > max) {
        max = result[r];
        cityId = r;
      }
    });

    const city = cities.filter((c) => c.value === cityId);
    return city[0] || { lat: 0, lng: 0 };
  }, [workspacesWithActiveBookings, cities]);

  return {
    statistics,
    loadingStatistics,
    loadingActiveBookings,
    loadingCities,
    workspacesWithActiveBookings,
    citiesByActiveBookings,
    selectedCoords,
    resetMapFilters,
    selectedMonthTab,
    monthTabs,
    setResetMapFilters,
    getWorkspacesForFilter,
    getDefaultCity,
    handleTabClick,
    handleListItemClick,
    setSelectedCoords,
  };
};
