import React from "react";
import { ITabTitleItem } from "../../utils/reactUtils";

interface IProps {
  tabs: ITabTitleItem[];
  onTabClick: (tabTitle: string) => void;
  activeTabId?: string;
  className?: string;
}

const TabsHeader: React.FC<IProps> = ({
  tabs,
  onTabClick,
  activeTabId,
  className,
}) => {
  const activeTabClass = "tab-selected";

  return (
    <div className={`tab-group ${className}`}>
      {tabs.map((t) => (
        <div
          key={t.tabId}
          className={`tab ${t.class ? t.class : ""} ${
            t.tabId === activeTabId ? activeTabClass : ""
          }`}
          onClick={() => onTabClick(t.tabId)}
        >
          {t.title}
        </div>
      ))}
    </div>
  );
};

export default TabsHeader;
