import React, { useCallback, useEffect, useState } from "react";
import { Map, useMap } from "@vis.gl/react-google-maps";
import { Select } from "antd";

import MapMarker from "./MapMarker";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import { ILocationSelectListItem } from "../../models/Dashboard/Interfaces/ILocationSelectListItem";
import { ICompanyWorkspacesWithActiveBookings } from "../../models/Dashboard/Interfaces/ICompanyWorkspacesWithActiveBookings";
import ActiveBookingsCard from "../Dashboard/ActiveBookingsCard";

export interface IProps {
  locations: ICompanyWorkspacesWithActiveBookings[];
  cities: ILocationSelectListItem[];
  workspaces: ILocationSelectListItem[];
  zoomOut?: number;
  zoomIn?: number;
  defaultCity:
    | ILocationSelectListItem
    | {
        lat: number;
        lng: number;
      };
  selectedCoords: {
    lat: number | null;
    lng: number | null;
  };
  resetMapFilters: boolean;
  setSelectedCoords: (coords: { lat: number; lng: number }) => void;
  onFiltersReset: () => void;
}

export const MAP_JS_ID = "pluriaMap";
export const MAP_CONFIG_ID = "53ffa5d283134f61";

const MapViewer: React.FC<IProps> = ({
  locations,
  cities,
  workspaces,
  zoomOut = 10,
  zoomIn = 16,
  defaultCity,
  selectedCoords,
  resetMapFilters,
  setSelectedCoords,
  onFiltersReset,
}) => {
  const [state, setState] = useState({
    locations,
    selectedLocation: null,
    singleSelectedCity: DefaultFilterValues.All.toString(),
    singleSelectedWorkspace: DefaultFilterValues.All.toString(),
    mapLat: defaultCity.lat,
    mapLng: defaultCity.lng,
    zoom: zoomOut,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      locations,
    }));
  }, [locations]);

  const map = useMap(MAP_JS_ID);

  useEffect(() => {
    if (!map) return;
    const google = window.google;

    if (selectedCoords?.lat && selectedCoords?.lng) {
      map?.setCenter(
        new google.maps.LatLng(selectedCoords.lat, selectedCoords.lng)
      );
      map?.setZoom(20);
    } else {
      map?.setCenter(new google.maps.LatLng(state.mapLat, state.mapLng));
      if (state.mapLat === 0 && state.mapLng === 0) {
        map?.setZoom(2);
      } else {
        map?.setZoom(state.zoom);
      }
    }
  }, [map, state.zoom, state.mapLat, state.mapLng, selectedCoords, zoomIn]);

  useEffect(() => {
    if (resetMapFilters) {
      setState((prevState) => ({
        ...prevState,
        locations,
        selectedLocation: null,
        singleSelectedCity: DefaultFilterValues.All.toString(),
        singleSelectedWorkspace: DefaultFilterValues.All.toString(),
        mapLat: defaultCity.lat,
        mapLng: defaultCity.lng,
        zoom: zoomOut,
      }));
      onFiltersReset();
    }
  }, [resetMapFilters]);

  const getSelectedLocationCard = () => {
    const location = state.locations.find(
      (location) => location.workspaceId === state.selectedLocation
    );

    return location ? (
      <ActiveBookingsCard
        key={location.workspaceId}
        workspaceWithActiveBookings={location}
      />
    ) : null;
  };

  const onSelectedLocationChange = useCallback((event, id, lat, lng) => {
    event.preventDefault();
    event.stopPropagation();

    setState((prevState) => ({
      ...prevState,
      selectedLocation: id,
      mapLat: lat,
      mapLng: lng,
      zoom: zoomIn,
    }));
  }, []);

  const onWorkspaceFilterChange = useCallback(
    (value, values) => {
      setSelectedCoords(null);
      setState((prevState) => ({
        ...prevState,
        singleSelectedWorkspace: value,
        singleSelectedCity: DefaultFilterValues.All.toString(),
        locations:
          value !== DefaultFilterValues.All.toString()
            ? locations.filter((e) => e.workspaceId.toString() === value)
            : locations,
        mapLat:
          value !== DefaultFilterValues.All.toString()
            ? values.lat
            : defaultCity.lat,
        mapLng:
          value !== DefaultFilterValues.All.toString()
            ? values.lng
            : defaultCity.lng,
        zoom: value !== DefaultFilterValues.All.toString() ? zoomIn : zoomOut,
      }));
    },
    [locations, defaultCity, zoomIn, zoomOut]
  );

  const onCityFilterChange = useCallback(
    (value, values) => {
      setSelectedCoords(null);
      setState((prevState) => ({
        ...prevState,
        singleSelectedCity: value,
        singleSelectedWorkspace: DefaultFilterValues.All.toString(),
        locations:
          value !== DefaultFilterValues.All.toString()
            ? locations.filter((e) => e.cityId.toString() === value)
            : locations,
        mapLat:
          value !== DefaultFilterValues.All.toString()
            ? values.lat
            : defaultCity.lat,
        mapLng:
          value !== DefaultFilterValues.All.toString()
            ? values.lng
            : defaultCity.lng,
        zoom: zoomOut,
      }));
    },
    [locations, defaultCity, zoomOut]
  );

  return (
    <>
      <Map
        id={MAP_JS_ID}
        mapId={MAP_CONFIG_ID}
        defaultZoom={state.zoom}
        defaultCenter={{ lat: state.mapLat, lng: state.mapLng }}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        clickableIcons={false}
        scrollwheel={true}
      >
        {state?.locations?.map((location) => {
          return (
            <MapMarker
              key={location.workspaceId}
              {...location}
              onSelectedLocationChange={onSelectedLocationChange}
            />
          );
        })}
      </Map>

      <div>{getSelectedLocationCard()}</div>
      <div className="map_filter">
        <div className="filter">
          <div className="input-group">
            <Select
              options={workspaces}
              onChange={onWorkspaceFilterChange}
              value={state.singleSelectedWorkspace}
              showSearch
              optionFilterProp="label"
            />
          </div>
        </div>
        <div className="filter">
          <div className="input-group">
            <Select
              options={cities}
              onChange={onCityFilterChange}
              value={state.singleSelectedCity}
              showSearch
              optionFilterProp="label"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MapViewer;
