import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import update from "immutability-helper";
import { BenefitItem } from "../models/Benefits/BenefitItem";
import UsersService from "../services/UsersService";

export module BenefitsStore {

    export interface IState {
        loading: boolean;
        userBenefits: BenefitItem[];
    }

    export enum Actions {
        InitializeBenefits = "BENEFITS_INITIALIZE",
        ToggleLoading = "BENEFITS_TOGGLE_LOADING",
        SetUserBenefits = "BENEFITS_SET_USER_BENEFIT",
    }

    interface IInitialize {
        type: Actions.InitializeBenefits
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    interface ISetUserBenefits {
        type: Actions.SetUserBenefits,
        userBenefits: BenefitItem[]
    }

    type KnownAction = IToggleLoading | ISetUserBenefits | IInitialize;

    export const actionCreators = {
        initializeBenefits: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.InitializeBenefits });
        },
        getUserBenefits: (userId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var result = await UsersService.getUserBenefits(userId).then(response => response.value);
            var userBenefits = UsersService.mapBenefitsToBenefitItemsList(result);

            dispatch({ type: Actions.SetUserBenefits, userBenefits: userBenefits });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        }
    }

    const initialState: IState = {
        loading: false,
        userBenefits: []
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.InitializeBenefits:
                return initialState;
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.SetUserBenefits:
                return update(currentState, {
                    userBenefits: { $set: action.userBenefits }
                });
            default:
                return currentState || initialState;
        }
    }

}