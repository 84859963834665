import { LoginStore } from "./LoginStore";
import { TabStore } from "./TabStore";
import { UserDetailsStore } from "./UserDetailsStore";
import { HotdeskBookingsGridStore } from "./HotdeskBookingsGridStore";
import { RoomBookingsGridStore } from "./RoomBookingsGridStore";
import { UsersGridStore } from "./UsersGridStore";
import { BenefitsStore } from "./BenefitsStore";

export type StateDictionary<T> = { [key: string]: T };

// The top-level state object
export interface ApplicationState {
  login: LoginStore.IState;
  tab: StateDictionary<TabStore.IState>;
  users: StateDictionary<UsersGridStore.IState>;
  userDetails: UserDetailsStore.IState;
  hotdeskBookingsGrid: StateDictionary<HotdeskBookingsGridStore.IState>;
  roomBookingsGrid: StateDictionary<RoomBookingsGridStore.IState>;
  benefits: BenefitsStore.IState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  login: LoginStore.reducer,
  tab: TabStore.reducer,
  userDetails: UserDetailsStore.reducer,
  hotdeskBookingsGrid: HotdeskBookingsGridStore.reducer,
  roomBookingsGrid: RoomBookingsGridStore.reducer,
  users: UsersGridStore.reducer,
  benefits: BenefitsStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export interface AppThunkActionAsync<TAction, TResult> {
  (
    dispatch: (action: TAction) => void,
    getState: () => ApplicationState
  ): Promise<TResult>;
}

export interface AppThunkArrayAction<TAction> {
  (
    dispatch: (action: TAction, key: string) => void,
    getState: () => ApplicationState
  ): void;
}

export interface AppThunkArrayActionAsync<TAction, TResult> {
  (
    dispatch: (action: TAction, key: string) => void,
    getState: () => ApplicationState
  ): Promise<TResult>;
}

// create methods with the key as parameter

export function initAllStores(dispatch, getState) {
  LoginStore.actionCreators.init()(dispatch, getState);
  LoginStore.initBoundActions(dispatch, getState);
}
