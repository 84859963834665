import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./store/configureStore";
import App from "./App";
import Globals from "./Globals";
import { IPublicSession } from "./models/Common/IPublicSession";
import { initAllStores } from "./store";

// Setup session
function setupSession() {
  Globals.reset();
  Globals.init(window["publicSession"] as IPublicSession);
}
setupSession();

// Configure the store
export const store = configureStore();
initAllStores(store.dispatch, store.getState);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const RootApp: React.FC = () => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000, // 1 minute
          },
        },
      })
  );

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

root.render(<RootApp />);
