import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import DashboardService from "../../services/DashboardService";

export const useDashboardData = (monthsValue) => {
  const { data: statistics, isLoading: loadingStatistics } = useQuery({
    queryKey: ["companyDashboardStatistics"],
    queryFn: DashboardService.getCompanyStatistics,
  });

  const {
    data: workspacesWithActiveBookings,
    isLoading: loadingActiveBookings,
    error: errorActiveBookings,
  } = useQuery({
    queryKey: ["companyActiveBookings", monthsValue],
    queryFn: () => DashboardService.getCompanyActiveBookings(monthsValue),
  });

  const {
    data: cities,
    isLoading: loadingCities,
    error: errorCities,
  } = useQuery({
    queryKey: ["allCities"],
    queryFn: DashboardService.getAllCities,
  });

  return {
    statistics: statistics?.value || {},
    workspacesWithActiveBookings: useMemo(
      () => workspacesWithActiveBookings?.value || [],
      [workspacesWithActiveBookings]
    ),
    cities: useMemo(() => cities?.value || [], [cities]),
    loadingStatistics,
    loadingActiveBookings,
    loadingCities,
    errorActiveBookings,
    errorCities,
  };
};
