import { Link } from "react-router-dom";
import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import General from "../../resources/AdminUI/General";
import HotdeskBookingsGrid from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { IHotdeskBookingsItem } from "./Interfaces/IHotdeskBookingsItem";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { formatDate, formatDateInZone } from "../../utils/date";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";

export class HotdeskBookingsItem extends BaseEntity implements IHotdeskBookingsItem {

    constructor(e: IHotdeskBookingsItem) {
        super();
        this.additionalConstructor(this, e);

        this.ForProperty(p => "id")
            .SetDisplayName(HotdeskBookingsGrid.Resources.visitId)
            .Show(e => <div className="column-xsmall">
              {
                  this.bookingGroupId && 
                  <div className="id_group_booking">
                    ID GROUP: #{this.bookingGroupId}
                  </div>
                }
                <p>{String_Format(HotdeskBookingsGrid.Resources.id, this.id)}</p>
            </div>);

        this.ForProperty(p => "userId")
            .SetDisplayName(HotdeskBookingsGrid.Resources.userInfo)
            .Show(_self => <div className="column-large">
                <Link className="table-item-link" to={ApplicationPathBuilder.ViewEmployeeDetails(this.userId)}>
                    <p>{this.userFirstName} {this.userLastName}</p>
                    <p>{String_Format(HotdeskBookingsGrid.Resources.userId, this.userId)}</p>
                </Link>
            </div>);

        this.ForProperty(p => "userEmail")
            .SetDisplayName(HotdeskBookingsGrid.Resources.contactInfo)
            .Show(_self =>
                <div className="column-xlarge">
                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">phone</span>
                        <p>{this.userPhone || '-'}</p>
                    </div>
                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">email</span>
                        <p>{this.userEmail || '-'}</p>
                    </div>
                </div>
            );

        this.ForProperty(p => "workspaceName")
            .SetDisplayName(HotdeskBookingsGrid.Resources.space)
            .Show(_self => <div className="column-large">
                <p>{this.workspaceName}</p>
                <p>{SpaceCategoryList.Resources[firstLetterToLowerCase(SpaceCategories[this.workspaceCategoryId])]} | {this.workspaceCityName}</p>
            </div>);

        this.ForProperty(p => "planName")
            .SetDisplayName(HotdeskBookingsGrid.Resources.entryType)
            .Show(_self => <div className="column-medium">
                <p>{this.planName}</p>
            </div>);

        this.ForProperty(p => "statusId")
            .SetDisplayName(HotdeskBookingsGrid.Resources.status)
            .Show(_self => {
                switch (this.statusId) {
                    case HotdeskBookingStatuses.Ended:
                        return <div className="column-large">
                            <div>
                              {this.statusName}
                                {/* {this.isFullDay.valueOf()
                                    ? String_Format(HotdeskBookingsGrid.Resources.statusFullDay, this.statusName)
                                    : String_Format(HotdeskBookingsGrid.Resources.statusHalfDay, this.statusName)} */}
                                {/* &nbsp;{General.Resources.at}
                                <p className="timestamp">
                                    {formatDateInZone(this.checkoutAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </p> */}
                            </div>
                        </div>
                    case HotdeskBookingStatuses.Cancelled:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp">{formatDate(this.cancelledAt)}</span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Unredemeed:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp">{formatDate(this.expiresAt)}</span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Scheduled:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp">
                                    {formatDateInZone(this.bookingAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Checkedin:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp">
                                    {formatDateInZone(this.checkinAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </p>
                        </div>
                }
            });

        this.ForProperty(p => "createdAt")
            .Show(_self => {
                switch (this.statusId) {
                    case HotdeskBookingStatuses.Ended:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp"> {formatDate(this.createdAt)} </span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp"> {formatDateInZone(this.bookingAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.checkinAt}
                                <span className="timestamp"> {formatDateInZone(this.checkinAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </div>
                            {/* <div className="span-details-row">{HotdeskBookingsGrid.Resources.checkoutAt}
                                {this.isAutomatedCheckout ?
                                    <span> {String_Format(HotdeskBookingsGrid.Resources.autoCheckout, formatDateInZone(this.checkoutAt, this.timezone), this.timezone)} </span>
                                    :
                                    <span className="timestamp"> {formatDateInZone(this.checkoutAt, this.timezone)}
                                        <span className="timezone">{this.timezone}</span>
                                    </span>
                                }
                            </div> */}
                            {/* <div className="span-details-row last">{HotdeskBookingsGrid.Resources.timeSpent}
                                <span> {this.isFullDay.valueOf() ?
                                    String_Format(HotdeskBookingsGrid.Resources.timeSpentFullDay, Math.floor(parseFloat(this.timeSpent)), Math.floor((parseFloat(this.timeSpent) - Math.floor(parseFloat(this.timeSpent))) * 60))
                                    :
                                    String_Format(HotdeskBookingsGrid.Resources.timeSpentHalfDay, Math.floor(parseFloat(this.timeSpent)), Math.floor((parseFloat(this.timeSpent) - Math.floor(parseFloat(this.timeSpent))) * 60))
                                }
                                </span>
                            </div> */}

                        </div >
                    case HotdeskBookingStatuses.Cancelled:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp"> {formatDate(this.createdAt)} </span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp"> {formatDateInZone(this.bookingAt, this.timezone)} <span className="timezone">{this.timezone} </span></span>
                            </div>
                            <div className="span-details-row last">
                                {HotdeskBookingsGrid.Resources.cancelledAt}
                                <span className="timestamp"> {formatDate(this.cancelledAt)} </span>
                            </div>
                        </div>
                    case HotdeskBookingStatuses.Unredemeed:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp"> {formatDate(this.createdAt)} </span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp"> {formatDateInZone(this.bookingAt, this.timezone)} <span className="timezone">{this.timezone} </span></span>
                            </div>
                            <div className="span-details-row last">
                                {HotdeskBookingsGrid.Resources.unredemeedAt}
                                <span className="timestamp"> {formatDate(this.expiresAt)} - {HotdeskBookingsGrid.Resources.userDidntCancelSoBookingExpiredAndWasUnredeemed} </span>
                            </div>
                        </div>
                    case HotdeskBookingStatuses.Scheduled:
                    case HotdeskBookingStatuses.Checkedin:
                    default:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp"> {formatDate(this.createdAt)} </span>
                            </div>
                            <div className="span-details-row last">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp"> {formatDateInZone(this.bookingAt, this.timezone)} <span className="timezone">{this.timezone} </span></span>
                            </div>
                        </div>
                }
            });
    }

    id: number;
    createdAt: string;
    unixBookingAtWithTimezone: number;
    bookingAt: string;
    checkinAt: string;
    checkoutAt: string;
    cancelledAt: string;
    expiresAt: string;
    userId: number;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    workspaceName: string;
    workspaceCategoryId: number;
    workspaceCategoryName: string;
    workspaceCityName: string;
    timeSpent: string;
    isFullDay?: boolean;
    isAutomatedCheckout?: boolean;
    planName: string;
    timezone: string;
    statusId: number;
    statusName: string;
    bookingGroupId?: number;
}