import React from "react";
import Helmet from "react-helmet";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { NavigateFunction } from "react-router-dom";

import { RoomBookingsGridStore } from "../../store/RoomBookingsGridStore";
import RoomBookingsTable from "../../components/RoomBookings/RoomBookingsTable";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";

import { withRouter } from "../../components/Shared/withRouter";
import { roomBookingsTableHiddenColumns } from "../../hooks/RoomBookingsGridPage/constants";
import { useRoomBookingsGridPage } from "../../hooks/RoomBookingsGridPage/useRoomBookingsGridPage";
import { FilterBar } from "../../components/RoomBookings/FilterBar";

interface RootState {
  roomBookingsGrid: {
    [componentId: string]: {
      filters: string;
      searchTerm: string;
      singleSelectedStatus: string;
      startDateSearchTemplate: number | null;
      endDateSearchTemplate: number | null;
    };
  };
}

interface IProps extends ConnectedProps<typeof connector> {
  navigate: NavigateFunction;
}

const RoomBookingsGridPage: React.FC<IProps> = (props) => {
  const {
    filters,
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
  } = props;
  const {
    onSearchFilterChange,
    onStatusFilterChange,
    onDateChange,
    getAllRoomStatuses,
    exportLogs,
  } = useRoomBookingsGridPage(props);

  const filterBarProps = {
    searchTerm,
    onSearchFilterChange,
    getAllRoomStatuses,
    singleSelectedStatus,
    onStatusFilterChange,
    startDateSearchTemplate,
    endDateSearchTemplate,
    onDateChange,
  };

  return (
    <div>
      <Helmet title={RoomBookingsGrid.Resources.roomsLogs} />
      <div className="page-top-container">
        <div className="page-title">{RoomBookingsGrid.Resources.roomsLogs}</div>
        <div className="page-actions">
          <div className="btn-big btn-secondary" onClick={exportLogs}>
            <p className="btn-secondary-text">
              {RoomBookingsGrid.Resources.exportRoomsLogsCsv}
            </p>
          </div>
        </div>
      </div>
      <FilterBar {...filterBarProps} />
      <RoomBookingsTable
        componentId={componentId}
        hiddenColumns={roomBookingsTableHiddenColumns}
        expandableColumns={["userId"]}
        filters={filters}
      />
    </div>
  );
};

const componentId = "RoomBookingGridPage";

const connector = connect(
  (state: RootState) => {
    const componentState = state.roomBookingsGrid[componentId];

    return {
      filters: componentState?.filters || "",
      searchTerm: componentState?.searchTerm || "",
      singleSelectedStatus: componentState?.singleSelectedStatus || "0",
      startDateSearchTemplate: componentState?.startDateSearchTemplate || null,
      endDateSearchTemplate: componentState?.endDateSearchTemplate || null,
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        ...RoomBookingsGridStore.getActionCreators(componentId),
      },
      dispatch
    )
);

export default withRouter(connector(RoomBookingsGridPage));
