import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserDetailsCard from "../../components/Users/Cards/UserDetailsCard";
import { UserDetailsStore } from "../../store/UserDetailsStore";
import { ApplicationState } from "../../store";
import Helmet from "react-helmet";
import UserDetails from "../../resources/AdminUI/Users/UserDetails";
import General from "../../resources/AdminUI/General";
import { NavigateFunction } from "react-router-dom";
import { withRouter } from "../../components/Shared/withRouter";
import UserBenefitsContainer from "../../components/Users/Containers/UserBenefitsContainer";
import UsersBookingsTablesTabComponent from "../../components/Users/Tabs/UsersBookingsTablesTabComponent";
import { String_Format } from "../../utils/utils";
import { BenefitsStore } from "../../store/BenefitsStore";
import { IUserDetails } from "../../models/Users/Interfaces/IUserDetails";
import {
  hotdeskBookingsTableHiddenColumns,
  roomBookingsTableHiddenColumns,
} from "../../hooks/Users/constants";
import Loading from "../../components/Shared/Loading";

interface IProps {
  user: IUserDetails;
  params: URLSearchParams;
  getUserDetails: (userId: number) => void;
  navigate?: NavigateFunction;
  initializeBenefits?: () => void;
  loading?: boolean;
}

const UserDetailsPage = (props: IProps) => {
  const {
    loading,
    user,
    params,
    navigate,
    getUserDetails,
    initializeBenefits,
  } = props;
  const userIdFromParams = params.get("id");

  useEffect(() => {
    if (userIdFromParams) {
      const userId = parseInt(userIdFromParams, 10);
      getUserDetails(userId);
      initializeBenefits();
    }
  }, [userIdFromParams]);

  const tableFilterTemplate = `UserId eq ${user?.id}`;

  return loading || !user ? (
    <div>
      <Loading />
    </div>
  ) : (
    <div>
      <Helmet title={UserDetails.Resources.userDetailsHeader}></Helmet>

      <div className="details-page-top">
        <div className="page-navigation">
          <button
            className="material-symbols-outlined"
            onClick={() => navigate(-1)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(-1);
              }
            }}
          >
            arrow_back
          </button>
          <div className="page-title">
            {UserDetails.Resources.userDetailsHeader}
          </div>
        </div>
      </div>

      <UserDetailsCard user={user} />

      <section className="container">
        <UserBenefitsContainer
          userId={user.id}
          userName={user.lastName + " " + user.firstName}
        />
      </section>

      <section className="container">
        <UsersBookingsTablesTabComponent
          componentId={`user-bookings-${user.id}`}
          userId={user.id}
          hotdeskBookingsTableFilters={String_Format(
            tableFilterTemplate,
            user.id
          )}
          hotdeskBookingsTableHiddenColumns={hotdeskBookingsTableHiddenColumns}
          hotdeskBookingsTableExpandableColumns={["createdAt"]}
          roomBookingsTableFilters={String_Format(tableFilterTemplate, user.id)}
          roomBookingsTableHiddenColumns={roomBookingsTableHiddenColumns}
          roomBookingsTableExpandableColumns={["userId"]}
        ></UsersBookingsTablesTabComponent>
      </section>
    </div>
  );
};

export default withRouter(
  connect(
    (state: ApplicationState) => {
      return {
        user: state.userDetails.user,
        loading: state.userDetails.loading,
      };
    },
    {
      initializeBenefits: BenefitsStore.actionCreators.initializeBenefits,
      getUserDetails: UserDetailsStore.actionCreators.getUserDetails,
    }
  )(UserDetailsPage)
);
