import { FC } from "react";

import Dashboard from "../../resources/AdminUI/CompanyDashboard/Dashboard";

export interface IAccountsDetailsContentCardProps {
  activeUsers: number;
  totalUsers: number;
}

const AccountsDetailsContentCard: FC<IAccountsDetailsContentCardProps> = (
  props
) => {
  const { activeUsers, totalUsers } = props;
  return (
    <>
      <div
        className="widget_title underlined"
        dangerouslySetInnerHTML={{
          __html: Dashboard.Resources.noActiveAccounts,
        }}
      ></div>
      <div>
        <span className="users_active">{activeUsers}</span> / {totalUsers}
      </div>
    </>
  );
};

export default AccountsDetailsContentCard;
