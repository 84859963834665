import React, { FC } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";

import HotdeskBookingsGridPageTable from "../../components/HotdeskBookings/HotdeskBookingsTable";
import { ApplicationState } from "../../store";
import { HotdeskBookingsGridStore } from "../../store/HotdeskBookingsGridStore";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";
import HotdeskBookingsGrid from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import {
  IHotdeskBookingsGridPageProps,
  useHotdeskBookingsGridPage,
} from "../../hooks/HotdeskBookingsGridPage/useHotdeskBookingsGridPage";

const { RangePicker } = DatePicker;

const HotdeskBookingsGridPage: FC<IHotdeskBookingsGridPageProps> = (props) => {
  const {
    filters,
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
  } = props;

  const {
    exportLogs,
    onSearchFilterChange,
    onStatusFilterChange,
    onDateChange,
    getAllHodeskStatuses,
  } = useHotdeskBookingsGridPage(props);

  const dateFormat = "DD-MM-YYYY";

  return (
    <div>
      <Helmet title={HotdeskBookingsGrid.Resources.desksLogs} />
      <div className="page-top-container">
        <div className="page-title">
          {HotdeskBookingsGrid.Resources.desksLogs}
        </div>
        <div className="page-actions">
          <div className="btn-big btn-secondary" onClick={exportLogs}>
            <p className="btn-secondary-text">
              {HotdeskBookingsGrid.Resources.exportDesksLogsCsv}
            </p>
          </div>
        </div>
      </div>
      <div className="filter-bar">
        <div className="filter-search">
          <div className="input-group">
            <span className="icon material-symbols-outlined">search</span>
            <input
              type="text"
              className="form-control"
              placeholder={
                HotdeskBookingsGrid.Resources.searchByLogIdUserNameOrSpaceName
              }
              onChange={onSearchFilterChange}
              name="searchTerm"
              value={searchTerm}
            />
          </div>
        </div>
        <div className="filter-item">
          <div className="input-group">
            <Select
              options={getAllHodeskStatuses()}
              onChange={onStatusFilterChange}
              value={singleSelectedStatus}
            />
          </div>
        </div>
        <RangePicker
          value={[
            startDateSearchTemplate
              ? dayjs.unix(startDateSearchTemplate)
              : null,
            endDateSearchTemplate ? dayjs.unix(endDateSearchTemplate) : null,
          ]}
          format={dateFormat}
          onChange={onDateChange}
        />
      </div>
      <HotdeskBookingsGridPageTable
        hiddenColumns={[
          "createdAt",
          "unixBookingAtWithTimezone",
          "bookingAt",
          "checkinAt",
          "checkoutAt",
          "cancelledAt",
          "expiresAt",
          "statusName",
          "timeSpent",
          "timezone",
          "isFullDay",
          "isAutomatedCheckout",
          "userFirstName",
          "userLastName",
          "userPhone",
          "workspaceCategoryId",
          "workspaceCategoryName",
          "workspaceCityName",
          "bookingGroupId",
        ]}
        expandableColumns={["createdAt"]}
        filters={filters}
        componentId={componentId}
      />
    </div>
  );
};

const componentId = "HotdeskBookingGridPage";

export default connect((state: ApplicationState) => {
  const componentState = state.hotdeskBookingsGrid[componentId];
  return {
    filters: componentState?.filters || "",
    searchTerm: componentState?.searchTerm || "",
    singleSelectedStatus:
      componentState?.singleSelectedStatus ||
      DefaultFilterValues.All.toString(),
    startDateSearchTemplate: componentState?.startDateSearchTemplate || null,
    endDateSearchTemplate: componentState?.endDateSearchTemplate || null,
  };
}, HotdeskBookingsGridStore.getActionCreators(componentId))(
  HotdeskBookingsGridPage
);
