import React from "react";
import UserDetails from "../../../resources/AdminUI/Users/UserDetails";
import { String_Format, firstLetterToLowerCase } from "../../../utils/utils";
import { IUserDetails } from "../../../models/Users/Interfaces/IUserDetails";
import { UserStates } from "../../../enums/Users/UserStates";
import UserStateTypes from "../../../resources/AdminUI/Users/UserStateTypes";
import { formatDate } from "../../../utils/date";

interface IProps {
  user: IUserDetails;
}

const UserDetailsCard: React.FC<IProps> = ({ user }) => {
  return (
    <div className="two-columns-container">
      <section className="container">
        <h2 className="item-name">
          {user.firstName} {user.lastName}
        </h2>
        <div className="db_info">
          <p>
            <span
              className={`status ${firstLetterToLowerCase(
                UserStates[user.status]
              )}`}
            >
              {
                UserStateTypes.Resources[
                  firstLetterToLowerCase(UserStates[user.status])
                ]
              }
            </span>
          </p>
          <div className="id_created_on">
            | {String_Format(UserDetails.Resources.idLabel, user.id)}|{" "}
            {String_Format(
              UserDetails.Resources.accountCretedOnLabel,
              formatDate(user.createdAt)
            )}
          </div>
        </div>

        <div className="header">{UserDetails.Resources.desksActivityLabel}</div>
        <p>
          {String_Format(
            UserDetails.Resources.currentMonthBookings,
            user.hotdeskCurrentMonthBookings || 0,
            user.hotdeskCurrentMonthCheckins || 0
          )}
        </p>
        <p>
          {String_Format(
            UserDetails.Resources.totalBookings,
            user.hotdeskTotalBookings || 0,
            user.hotdeskTotalCheckins || 0
          )}
        </p>

        <div className="header">{UserDetails.Resources.roomsActivityLabel}</div>
        <p>
          {String_Format(
            UserDetails.Resources.rommBookingsLabel,
            user.roomBookings || 0
          )}
        </p>
      </section>

      <section className="container">
        <div className="header">{UserDetails.Resources.contactInfoLabel}</div>
        <div className="user-info-row">
          <span className="material-symbols-outlined">phone</span>
          <p className="text">{user.phone}</p>
        </div>
        <div className="user-info-row">
          <span className="material-symbols-outlined">email</span>
          <p className="text">{user.email}</p>
        </div>
      </section>
    </div>
  );
};

export default UserDetailsCard;
