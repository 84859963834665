import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthorizedLayoutComponent } from "./layouts/Layout";
import { ApplicationPaths } from "./enums/Common/ApplicationPaths";
import UserDetails from "./pages/Users/UserDetailsPage";
import HotdeskBookingsGridPage from "./pages/HotdeskBookings/HotdeskBookingsGridPage";
import RoomBookingsGridPage from "./pages/RoomBookings/RoomBookingsGridPage";
import UsersGridPage from "./pages/Users/UsersGridPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import NotFoundPage from "./pages/NotFoundPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";

const App: React.FC = () => {
  return (
    <Routes>
      <Route
        path={ApplicationPaths.EmptyPath}
        element={<Navigate to={ApplicationPaths.Dashboard} replace />}
      />
      <Route
        path={ApplicationPaths.Unauthorized}
        element={<UnauthorizedPage />}
      />
      <Route
        path={ApplicationPaths.Dashboard}
        element={
          <AuthorizedLayoutComponent>
            <DashboardPage />
          </AuthorizedLayoutComponent>
        }
      />
      <Route
        path={ApplicationPaths.ViewEmployeeDetails}
        element={
          <AuthorizedLayoutComponent>
            <UserDetails />
          </AuthorizedLayoutComponent>
        }
      />
      <Route
        path={ApplicationPaths.Employees}
        element={
          <AuthorizedLayoutComponent>
            <UsersGridPage />
          </AuthorizedLayoutComponent>
        }
      />
      <Route
        path={ApplicationPaths.DesksLogs}
        element={
          <AuthorizedLayoutComponent>
            <HotdeskBookingsGridPage />
          </AuthorizedLayoutComponent>
        }
      />
      <Route
        path={ApplicationPaths.RoomsLogs}
        element={
          <AuthorizedLayoutComponent>
            <RoomBookingsGridPage />
          </AuthorizedLayoutComponent>
        }
      />
      <Route
        path={ApplicationPaths.NotFound}
        element={
          <AuthorizedLayoutComponent>
            <NotFoundPage />
          </AuthorizedLayoutComponent>
        }
      />
      <Route
        path="*"
        element={
          <AuthorizedLayoutComponent>
            <NotFoundPage />
          </AuthorizedLayoutComponent>
        }
      />
    </Routes>
  );
};

export default App;
