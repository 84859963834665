import update from "immutability-helper";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import UserService from "../services/UsersService";
import { IUserDetails } from "../models/Users/Interfaces/IUserDetails";

export module UserDetailsStore {

    export interface IState {
        user: IUserDetails,
        loading: boolean,
    }

    export enum Actions {
        GetUserDetails = "USER_DETAILS_GET_USER_DETAILS",
        ToggleLoading = "USER_DETAILS_TOGGLE_LOADING",
    }

    interface IGetUserDetails {
        type: Actions.GetUserDetails,
        userDetails: IUserDetails
    }

    interface IToggleLoading {
        type: Actions.ToggleLoading;
        loading: boolean;
    }

    type KnownAction = IGetUserDetails | IToggleLoading;

    export const actionCreators = {
        getUserDetails: (userId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            dispatch({ type: Actions.ToggleLoading, loading: true });

            var user = await UserService.getUserDetails(userId).then(respose => respose.value);

            dispatch({ type: Actions.GetUserDetails, userDetails: user });

            dispatch({ type: Actions.ToggleLoading, loading: false });
        }
    }

    const initialState: IState = {
        user: null,
        loading: false
    }

    export const reducer: Reducer<IState> = (currentState: IState, incomingAction: Action) => {
        const action = incomingAction as KnownAction;

        switch (action.type) {
            case Actions.ToggleLoading:
                return { ...currentState, loading: action.loading }
            case Actions.GetUserDetails:
                return update(currentState,
                    {
                        user: { $set: action.userDetails }
                    });
            default:
                return currentState || initialState;
        }
    }
}