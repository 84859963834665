import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { ApplicationState } from "../store/index";
import ErrorMessages from "../resources/Common/ErrorMessages";
import General from "../resources/AdminUI/General";
import { LoginStore } from "../store/LoginStore";

const UnauthorizedPage = ({ logout }: { logout: () => void }) => {
  return (
    <div>
      <Helmet>
        <title>{ErrorMessages.Resources.unauthorizedErrorMessage}</title>
      </Helmet>
      <div className="container container-content">
        <div className="panel">
          <div className="panel-body">
            <h4 className="text-center">
              {ErrorMessages.Resources.unauthorizedErrorMessage}
            </h4>
            <section>
              <button onClick={() => logout()}>
                {General.Resources.logout}
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = {
  logout: LoginStore.actionCreators.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnauthorizedPage);
