import Globals from "./../../../Globals";

export default class Dashboard {
  public bookedLabel: string = `Booked`;
  public checkedInAt: string = `Checked-in at {0}`;
  public checkedInLabel: string = `checked-in`;
  public confirmedRoomBookings: string = `{0} confirmed room bookings`;
  public currentCheckinsTooltip: string = `Number of unique users this month`;
  public currentlyCheckedIn: string = `currently checked-in`;
  public dashboardTitle: string = `Dashboard`;
  public deskBooking: string = `Desk booking`;
  public employee: string = `employee`;
  public employees: string = `employees`;
  public helloHeader: string = `Hello, {0}`;
  public noActiveAccounts: string = `Number of <b>active accounts</b>`;
  public noHotdesksActivity: string = `No current activity for hot desks`;
  public noPreferredSpacesYet: string = `No preferred spaces yet`;
  public noRoomActivity: string = `No current activity for rooms`;
  public overallLabel: string = `Overall`;
  public preferredSpacesTooltip: string = `Based on users hot desks check-ins and rooms confirmed bookings, until now`;
  public preferredWorkingSpaces: string = `Top spaces by hotdesk check-ins`;
  public roomBooking: string = `Room booking`;
  public searchForASpacePlaceholder: string = `Search for a space`;
  public showPlacesFromLabel: string = `Show places from`;
  public startedAt: string = `Started at {0}`;
  public thisMonthLabel: string = `This month`;
  public totalCheckins: string = `Total <b>check-ins</b>`;
  public totalCheckinsTooltip: string = `Number of unique users overall`;
  public visitLabel: string = `visit`;
  public visitsLabel: string = `visits`;
  public monthData: string = `{0} Month Data`;

  public static get Resources(): Dashboard {
    switch (Globals.currentActiveLanguage) {
      default:
        return new Dashboard();
    }
  }
}
