import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationState } from "../../store";
import UsersTable from "../../components/Users/Tables/UsersTable";
import { UsersGridStore } from "../../store/UsersGridStore";
import { UserStateFilterOptions } from "../../enums/Users/UserStateFilterOptions";
import { withRouter } from "../../components/Shared/withRouter";
import UsersGrid from "../../resources/AdminUI/Users/UsersGrid";
import { useUsersGridPage } from "../../hooks/Users/useUsersGridPage";
import { hiddenColumnsForUsersTable } from "../../hooks/Users/constants";
import FilterBar from "../../components/Users/FilterBar";

const UsersGridPage = ({
  searchedTerm,
  selectedStateId,
  setFilter,
  changeCurrentPage,
  reload,
  setSearchedTerm,
  setSelectedStateId,
}) => {
  const {
    getUserStates,
    handleOnSearchFilterChange,
    handleOnStateFilterChange,
  } = useUsersGridPage({
    searchedTerm,
    selectedStateId,
    setFilter,
    changeCurrentPage,
    reload,
    setSearchedTerm,
    setSelectedStateId,
  });

  const filterBarProps = {
    handleOnSearchFilterChange,
    searchedTerm,
    getUserStates,
    handleOnStateFilterChange,
    selectedStateId,
  };

  return (
    <div>
      <Helmet title={UsersGrid.Resources.users}></Helmet>
      <div className="page-top-container">
        <div className="page-title">{UsersGrid.Resources.employees}</div>
      </div>
      <FilterBar {...filterBarProps} />
      <UsersTable
        componentId={componentId}
        hiddenColumns={hiddenColumnsForUsersTable}
      />
    </div>
  );
};

const componentId = "UsersGridPage";

export default withRouter(
  connect(
    (state: ApplicationState) => {
      const componentState = state.users[componentId];
      return {
        filters: componentState?.filters || "",
        searchedTerm: componentState?.searchedTerm || "",
        selectedStateId:
          componentState?.selectedStateId ||
          UserStateFilterOptions.AllUserStates.toString(),
      };
    },
    (dispatch) =>
      bindActionCreators(
        {
          ...UsersGridStore.getActionCreators(componentId),
        },
        dispatch
      )
  )(UsersGridPage)
);
