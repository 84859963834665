import Globals from "./../../../Globals";

export default class HotdeskBookingsGrid {
       public addDeskLog: string = `Add desk log`;
       public autoCheckout: string = `{0} {1} - <b>auto-check-out</b>`;
       public bookingCreatedAt: string = `Booking created at:`;
       public bookingFor: string = `Booking for:`;
       public bookingRequired: string = `Booking required`;
       public cancelledAt: string = `Cancelled at: `;
       public checkinAt: string = `Check-in at: `;
       public checkoutAt: string = `Check-out at: `;
       public company: string = `Company`;
       public companyDashboardExportHotdeskBookingLogsHeader: string = `Id,Created At,Booking At,Checkin At,Cancelled At,Expires At,Status Name,Plan Name,Is Automated Checkout,User Id,User First Name,User Last Name,User Email,User Phone,Workspace Name,Workspace Category Name,Workspace City Name, Group Booking Id`;
       public companyId: string = `Company ID: #{0}`;
       public contactInfo: string = `Contact info`;
       public csvExtension: string = `.csv`;
       public desksLogs: string = `Desks Logs`;
       public enterprise: string = `Enterprise`;
       public entryType: string = `Entry type`;
       public exportDesksLogsCsv: string = `Export Desks Logs.csv`;
       public id: string = `ID #{0}`;
       public searchByLogIdOrUserName: string = `Search by log ID or user name `;
       public searchByLogIdUserNameOrClientName: string = `Search by log ID, user name, space name or customer name`;
       public searchByLogIdUserNameOrSpaceName: string = `Search by log ID, user name or space name`;
       public space: string = `Space`;
       public spaceId: string = `Space ID: #{0}`;
       public spaceType: string = `Space Type`;
       public status: string = `Status`;
       public statusFullDay: string = `{0}`;
       public statusHalfDay: string = `{0}`;
       public timeSpent: string = `Time spent:`;
       public timeSpentFullDay: string = `{0} hours {1} minutes`;
       public timeSpentHalfDay: string = `{0} hours {1} minutes`;
       public unredemeedAt: string = `Unredemeed at: `;
       public userDidntCancelSoBookingExpiredAndWasUnredeemed: string = `User didn't cancel, booking expired & was not redemeed`;
       public userId: string = `User ID: #{0}`;
       public userInfo: string = `User info`;
       public visitId: string = `Visit ID`;
       public walkin: string = `Walk-in`;

		public static get Resources(): HotdeskBookingsGrid{
			switch (Globals.currentActiveLanguage) {
				default:
				return new HotdeskBookingsGrid();
		}
   	}
}
