import { EntitiesList, IEntitiesList } from "../models/Common/IEntitiesList";
import Result from "../models/Common/Result"
import { IUserItem } from "../models/Users/Interfaces/IUserItem";
import { AppSettings } from "../utils/settings";
import { ServiceBase } from "./ServiceBase"
import { UserItem } from '../models/Users/UserItem';
import { IBenefit } from "../models/Benefits/Interfaces/IBenefit";
import { BenefitItem } from "../models/Benefits/BenefitItem";

export default class UsersService extends ServiceBase {

    static getUsers = (toTake: number, toSkip: number, filters?: string): Promise<Result<IEntitiesList<IUserItem>>> => {
        var url = `/odata/users?$top=${toTake}&$skip=${toSkip}`;

        if (filters && filters.length > 0) {
            url = `${url}&$filter=${filters}`;
        }

        try {
            var result = UsersService.requestJsonFetch<IEntitiesList<IUserItem>>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}${url}&$orderby=Id desc&$count=true`
            });
            return result;
        } catch (error) { }

        return new Promise<Result<IEntitiesList<IUserItem>>>(null);
    }

    static additionalMapping = (entitiesList: IEntitiesList<IUserItem>): EntitiesList<IUserItem> => {
        var newEntities = new EntitiesList<IUserItem>();
        newEntities.entities = entitiesList.entities.map((e) => new UserItem(e));
        newEntities.numberOfEntities = entitiesList.numberOfEntities;
        return newEntities;
    }

    static getUserDetails = (userId: number): Promise<Result<any>> => {
        try {
            var result = UsersService.requestJsonFetch<any>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}`
            });
        } catch (error) { }

        return result;
    }

    static getUserBenefits = (userId: number): Promise<Result<any>> => {
        try {
            var result = UsersService.requestJsonFetch<any>({
                method: "GET",
                url: `${AppSettings.CommonSettings.ApiUrl}/users/${userId}/benefits`
            });
        } catch (error) { }

        return result;
    }

    static mapBenefitsToBenefitItemsList = (benefitList: any): any => {
        var entities = new EntitiesList<IBenefit>();
        entities.entities = benefitList.map((e) => new BenefitItem(e));
        
        return entities.entities;
    }
}