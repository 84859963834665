import React, { useEffect } from "react";
import { connect } from "react-redux";

import { IBenefit } from "../../../models/Benefits/Interfaces/IBenefit";
import { BenefitItem } from "../../../models/Benefits/BenefitItem";
import General from "../../../resources/AdminUI/General";
import UserBenefits from "../../../resources/AdminUI/Benefits/UserBenefits";
import { ApplicationState } from "../../../store";
import { BenefitsStore } from "../../../store/BenefitsStore";
import BenefitsTable from "../Tables/BenefitsTable";
import Loading from "../../Shared/Loading";

interface IProps {
  userId: number;
  userName: string;
  loading?: boolean;
  benefits?: IBenefit[];

  getUserBenefits?: (userId: number) => void;
}

const UserBenefitsContainer: React.FC<IProps> = ({
  userId,
  userName,
  loading,
  benefits,
  getUserBenefits,
}) => {
  useEffect(() => {
    if (getUserBenefits) {
      getUserBenefits(userId);
    }
  }, [getUserBenefits, userId]);

  const hiddenColumns = [
    "id",
    "value",
    "currency",
    "userUsageLimit",
    "renewIntervalUnitId",
    "nextRenewAt",
    "companyId",
    "subscriptionName",
    "voucherId",
    "startFromCurrentCycle",
    "isUnlimited",
    "renewMaxCycles",
    "actions",
    "hasUserLimit",
  ];

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <div>
        <div className="section-top">
          <div className="section-title">
            {UserBenefits.Resources.userServicesText}
          </div>
        </div>
        {benefits && benefits?.length > 0 ? (
          <BenefitsTable
            userId={userId}
            beneficiaryName={userName}
            benefits={benefits as BenefitItem[]}
            hiddenColumns={hiddenColumns}
          />
        ) : (
          <p>{UserBenefits.Resources.noBenefitsText}</p>
        )}
      </div>
    );
  }
};

export default connect(
  (state: ApplicationState, ownProps: IProps) => ({
    ...ownProps,
    loading: state.benefits.loading,
    benefits: state.benefits.userBenefits,
  }),
  {
    getUserBenefits: BenefitsStore.actionCreators.getUserBenefits,
  }
)(UserBenefitsContainer);
