import React, { useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { LoginStore } from "../../store/LoginStore";
import { withRouter } from "../../components/Shared/withRouter";
import { Button, Input } from "antd";
import { ApplicationState } from "../../store";

interface ILoginProps {
  loginState: string;
  loginError: string;
  login: (email: string, password: string) => void;
}

const LoginPage = (props: ILoginProps) => {
  const { loginState, loginError, login } = props;
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  return (
    <div className="page login">
      <Helmet title={"Login"} />

      <div className="auth-box-container">
        {loginState == "LOGGING_IN" && <div>Logging in...</div>}

        {loginState == "LOGIN_FAILED" && <div>{loginError}</div>}

        <div className="input-section">
          <div className="logo-container">
            <img
              src="/assets/images/logo_app_dark.svg"
              className="logo-auth"
            ></img>
          </div>

          <div className="title">Company Dashboard</div>

          <div className="description">
            <span className="highlighted">Welcome!</span> Please complete the
            information below to sign in
          </div>

          <div className="input-fields">
            <Input
              placeholder="Email"
              value={loginCredentials?.email}
              onChange={(e) =>
                setLoginCredentials((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
            />
            <Input.Password
              placeholder="Password"
              value={loginCredentials?.password}
              onChange={(e) =>
                setLoginCredentials((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }))
              }
            />
          </div>

          <div className="button">
            <Button
              className="login-ant-btn-default"
              onClick={() =>
                login(loginCredentials?.email, loginCredentials?.password)
              }
            >
              Login
            </Button>
          </div>
        </div>

        <div className="illustration">
          <div className="photo-container">
            <img
              src="/assets/images/bg_login.png"
              className="auth-illustration"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect((state: ApplicationState) => {
    return {
      ...state.login,
    };
  }, LoginStore.actionCreators)(LoginPage)
);
