import React, { useMemo, useState } from "react";
import { ICompanyWorkspacesWithActiveBookings } from "../../models/Dashboard/Interfaces/ICompanyWorkspacesWithActiveBookings";
import Dashboard from "../../resources/AdminUI/CompanyDashboard/Dashboard";
import { String_Format } from "../../utils/utils";
import {
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  formatDateInZone,
} from "../../utils/date";
import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import { BookingLogTypes } from "../../enums/Common/BookingLogTypes";

interface IProps {
  workspaceWithActiveBookings: ICompanyWorkspacesWithActiveBookings;
}

const ActiveBookingsCard: React.FC<IProps> = ({
  workspaceWithActiveBookings,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const onCloseButtonHandler = () => {
    setIsVisible(false);
  };

  if (!workspaceWithActiveBookings) return null;

  const filteredBookings = useMemo(() => {
    const hotdeskBookings = workspaceWithActiveBookings.activeBookings.filter(
      (b) => b.featureId === BookingLogTypes.HotdeskLogs
    );
    const roomBookings = workspaceWithActiveBookings.activeBookings.filter(
      (b) => b.featureId === BookingLogTypes.RoomLogs
    );
    return { hotdeskBookings, roomBookings };
  }, [workspaceWithActiveBookings?.activeBookings]);

  return isVisible ? (
    <div className="workspace_card">
      <div className="top_section">
        <div>
          <span className="city_label">
            {workspaceWithActiveBookings.cityName},{" "}
          </span>
          <span className="address_label">
            {workspaceWithActiveBookings.workspaceAddress}
          </span>
        </div>
        <div className="ic_close" onClick={onCloseButtonHandler}>
          <span className="material-symbols-outlined">close</span>
        </div>
      </div>

      <div className="workspace_title">
        {workspaceWithActiveBookings.workspaceName}
      </div>

      {filteredBookings.hotdeskBookings.length === 0 ? (
        <div>{Dashboard.Resources.noHotdesksActivity}</div>
      ) : (
        <div>
          {filteredBookings.hotdeskBookings.length}{" "}
          {workspaceWithActiveBookings.activeBookings.length === 1
            ? Dashboard.Resources.employee
            : Dashboard.Resources.employees}{" "}
          {Dashboard.Resources.currentlyCheckedIn}
        </div>
      )}

      {filteredBookings?.roomBookings.length === 0 ? (
        <div>{Dashboard.Resources.noRoomActivity}</div>
      ) : (
        <div>
          {String_Format(
            Dashboard.Resources.confirmedRoomBookings,
            filteredBookings?.roomBookings.length
          )}
        </div>
      )}

      {workspaceWithActiveBookings.activeBookings.length > 0 && (
        <div className="active__bookings_container">
          {workspaceWithActiveBookings.activeBookings.map((user) => (
            <div key={user.bookingId} className="active_booking_card">
              <div className="name">
                {user.firstName} {user.lastName}
              </div>
              <div className="booking_type">
                {user.featureId === BookingLogTypes.HotdeskLogs
                  ? Dashboard.Resources.deskBooking
                  : Dashboard.Resources.roomBooking}
              </div>
              <div className="check_in">
                <div>
                  {String_Format(
                    user.featureId === BookingLogTypes.HotdeskLogs
                      ? Dashboard.Resources.checkedInAt
                      : Dashboard.Resources.startedAt,
                    formatDateInZone(
                      user.bookingStartTime,
                      workspaceWithActiveBookings.timezone,
                      DATE_TIME_FORMAT
                    )
                  )}
                </div>
                <div className="check_in_timezone">
                  {workspaceWithActiveBookings.timezone}
                </div>
              </div>
              {user.featureId === BookingLogTypes.RoomLogs && (
                <div>
                  {Dashboard.Resources.bookedLabel}{" "}
                  {RoomBookingTypes[user.bookingType]}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default ActiveBookingsCard;
