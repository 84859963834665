import { FC } from "react";
import { Select } from "antd";

import UsersGrid from "../../resources/AdminUI/Users/UsersGrid";
import { nameof } from "../../utils/utils";

export interface FilterBarProps {
  handleOnSearchFilterChange: (e: any) => void;
  searchedTerm: string;
  getUserStates: () => any;
  handleOnStateFilterChange: (value: string, option: any) => void;
  selectedStateId: any;
}

const FilterBar: FC<FilterBarProps> = (props) => {
  const {
    handleOnSearchFilterChange,
    searchedTerm,
    getUserStates,
    handleOnStateFilterChange,
    selectedStateId,
  } = props;
  return (
    <div className="filter-bar">
      <div className="filter-search">
        <div className="input-group">
          <span className="icon material-symbols-outlined">search</span>
          <input
            type="text"
            className="form-control"
            placeholder={
              UsersGrid.Resources.searchFilterForCompanyDashboardPlaceholder
            }
            onChange={handleOnSearchFilterChange}
            name={nameof((e) => e.searchFilter)}
            value={searchedTerm}
          />
        </div>
      </div>

      <div className="filter-item">
        <div className="input-group">
          <Select
            options={getUserStates()}
            onChange={handleOnStateFilterChange}
            value={selectedStateId}
          ></Select>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
