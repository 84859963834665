import Globals from "./../../../Globals";

export default class BenefitTypeList {
       public deskEntries: string = `Desk Entries`;
       public roomBudget: string = `Room Budget`;

		public static get Resources(): BenefitTypeList{
			switch (Globals.currentActiveLanguage) {
				default:
				return new BenefitTypeList();
		}
   	}
}
