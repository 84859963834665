import { Tooltip } from "antd";
import { FC } from "react";

import Dashboard from "../../resources/AdminUI/CompanyDashboard/Dashboard";

export interface ICheckinsContentCardProps {
  currentCheckIns: number;
  uniqueUsersCurrCheckins: number;
  totalCheckIns: number;
  uniqueUsersCheckins: number;
}

const CheckinsContentCard: FC<ICheckinsContentCardProps> = (props) => {
  const {
    currentCheckIns,
    uniqueUsersCheckins,
    totalCheckIns,
    uniqueUsersCurrCheckins,
  } = props;
  return (
    <>
      <div
        className="widget_title"
        dangerouslySetInnerHTML={{
          __html: Dashboard.Resources.totalCheckins,
        }}
      ></div>
      <div className="checkins_container">
        <div className="checkins_month">
          <div
            className="info_header"
            dangerouslySetInnerHTML={{
              __html: Dashboard.Resources.thisMonthLabel,
            }}
          ></div>
          <div className="counter_number">{currentCheckIns}</div>
          <div className="counter_users_container">
            <span className="material-symbols-outlined">group</span>
            <p className="users_number">{uniqueUsersCurrCheckins}</p>
            <Tooltip title={Dashboard.Resources.currentCheckinsTooltip}>
              <div className="ic_info">
                <span className="material-symbols-outlined">info_i</span>
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="checkins_overall">
          <div
            className="info_header"
            dangerouslySetInnerHTML={{
              __html: Dashboard.Resources.overallLabel,
            }}
          ></div>
          <div className="counter_number">{totalCheckIns}</div>
          <div className="counter_users_container">
            <span className="material-symbols-outlined">group</span>
            <p className="users_number">{uniqueUsersCheckins}</p>
            <Tooltip title={Dashboard.Resources.totalCheckinsTooltip}>
              <div className="ic_info">
                <span className="material-symbols-outlined">info_i</span>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckinsContentCard;
