import React from "react";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <div>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <div className="page login">
        <div className="container container-content">
          <div className="panel">
            <div className="panel-body">
              <h4 className="text-center">Not found</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
