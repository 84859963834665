export enum ApplicationPaths {
    Unauthorized = "/unauthorized",
    EmptyPath = "/",

    Dashboard = '/dashboard',

    ViewEmployeeDetails = "/employees/details",
    Employees = "/employees",

    DesksLogs = "/desks-logs",

    RoomsLogs = "/rooms-logs",

    NotFound = "/not-found",
}

export class ApplicationPathBuilder {

    static ViewEmployeeDetails = (id: number): string => {
        return `${ApplicationPaths.ViewEmployeeDetails}?id=${id}`
    }
}
