import HotdeskBookingsGrid from "../../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";

export const prepareCsvContent = (entities) => {
  if (entities && entities.length > 0) {
    const csvContent = entities
      .map((entity) => Object.values(entity).join(","))
      .join("\n");
    return (
      HotdeskBookingsGrid.Resources
        .companyDashboardExportHotdeskBookingLogsHeader +
      "\n" +
      csvContent
    );
  }
  return "";
};

// Helper function to trigger CSV download
export const downloadCsv = (csvContent) => {
  const blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8;",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute(
    "download",
    `${HotdeskBookingsGrid.Resources.desksLogs}_${new Date().toISOString()}.csv`
  );
  document.body.appendChild(link);
  link.click();
};
