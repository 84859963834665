import React, { useState } from "react";
import { NavigateFunction } from "react-router";
import { BenefitItem } from "../../../models/Benefits/BenefitItem";

interface IProps {
  benefits?: BenefitItem[];
  clientId?: number;
  beneficiaryName?: string;
  userId?: number;
  hiddenColumns?: string[];
  hasServerSideErrors?: boolean;
  errors?: string;
  navigate?: NavigateFunction;
  getClientBenefits?: (clientId: number) => any;
  deactivateBenefit?: (
    benefitId: number,
    deactivateFromCurrentCycle: boolean
  ) => any;
  deleteBenefit?: (benefitId: number) => any;
}

const BenefitsTable: React.FC<IProps> = ({
  benefits = [],
  hiddenColumns = [],
}) => {
  const tableClass: string = "table";

  return (
    <div className="table-wrapper">
      <table className={tableClass}>
        <thead>
          <tr>
            {benefits[0]?.TrueProperties.filter(
              (p) => hiddenColumns.indexOf(p) < 0
            ).map((p) => (
              <th key={p}>{benefits[0].GetDisplayNameForProperty(p)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {benefits.map((benefit) => (
            <tr key={benefit.id}>
              {benefit.TrueProperties.filter(
                (p) => hiddenColumns.indexOf(p) < 0
              ).map((p) => (
                <td key={p}>{benefit.GetDisplayValueForProperty(p)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BenefitsTable;
