import Globals from "./../../../Globals";

export default class UsersGrid {
       public accountOwnerLabel: string = `✓ Account Owner`;
       public activityGridHeader: string = `Activity`;
       public anyUserStatesLabel: string = `Any User States`;
       public bookingManagerLabel: string = `✓ Booking Manager`;
       public changeTeamAction: string = `Change team`;
       public checkinsThisMonth: string = `{0} check-in(s) this month`;
       public contactInfoGridHeader: string = `Contact info`;
       public createAtHeaderLabel: string = `Created at`;
       public csvExtension: string = `.csv`;
       public employees: string = `Employees`;
       public exportFileForImportUsersButtonLabel: string = `Export file for importing users`;
       public exportFileForImportUsersHeader: string = `email,firstName,lastName,phoneNumber`;
       public exportUsersButtonText: string = `Export users.csv`;
       public exportUsersHeader: string = `Id,First Name,Last Name,Email,Phone,Status,Deactivated,Created At,Is Phone Preferred Channel,Is Sms Preferred Channel,Is Email Preferred Channel,Customer Id,Customer Name,Team Id,Team Name,On Boarding Complete,Is Account Owner,Is Booking Manager,Current Month Checkins,Total Checkins`;
       public idLabel: string = `ID: #{0}`;
       public marketingPreferencesGridHeader: string = `Preferred channels`;
       public nameGridHeader: string = `Name`;
       public noCheckinsThisMonth: string = `No check-ins this month`;
       public noCheckinsYet: string = `No check-ins yet`;
       public noCommunicationChannels: string = `No channels selected`;
       public noRoleLabel: string = `No Role`;
       public planGridHeader: string = `Plan`;
       public roleGridHeader: string = `Role`;
       public searchFilterForCompanyDashboardPlaceholder: string = `Search for employee by name, email or ID`;
       public searchFilterPlaceholder: string = `Search by user name, email, ID or customer name`;
       public totalCheckins: string = `{0} check-in(s) overall`;
       public users: string = `Users`;

		public static get Resources(): UsersGrid{
			switch (Globals.currentActiveLanguage) {
				default:
				return new UsersGrid();
		}
   	}
}
