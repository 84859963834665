import { Link } from "react-router-dom";
import { UserStates } from "../../enums/Users/UserStates";
import UsersGrid from "../../resources/AdminUI/Users/UsersGrid";
import { formatDate } from "../../utils/date";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format, firstLetterToLowerCase } from "../../utils/utils";
import { IUserItem } from "./Interfaces/IUserItem";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";

export class UserItem extends BaseEntity implements IUserItem {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    status: number;
    deactivated: boolean;
    currentMonthCheckins: number;
    createdAt: string;
    teamId: number;
    teamName: string;
    onBoardingComplete: boolean;
    totalCheckins: number;

    constructor(user: IUserItem) {
        super();
        this.additionalConstructor(this, user);

        this.ForProperty(p => "firstName")
            .SetDisplayName(UsersGrid.Resources.nameGridHeader)
            .Show(_self => <div className="column-large">
                <Link className="table-item-link" to={ApplicationPathBuilder.ViewEmployeeDetails(this.id)}>
                    <p className="table-item-name">{this.firstName} {this.lastName}</p>
                </Link>
                <p><span className={`table-item-status ${firstLetterToLowerCase(UserStates[this.status])}`}>{[(UserStates[this.status])]} </span> | <span className="table-item-id">{String_Format(UsersGrid.Resources.idLabel, this.id)}</span></p>
            </div>);

        this.ForProperty(p => "email")
            .SetDisplayName(UsersGrid.Resources.contactInfoGridHeader)
            .Show(_self =>
                <div className="column-xlarge">

                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">phone</span>
                        <p>{this.phone || " - "}</p>
                    </div>

                    <div className="table-item-icon-info">
                        <span className="material-symbols-outlined">email</span>
                        <p>{this.email || " - "}</p>
                    </div>

                </div>
            );

        this.ForProperty(p => "currentMonthCheckins")
            .SetDisplayName(UsersGrid.Resources.activityGridHeader)
            .Show(_self => <div className="column-medium">
                {
                    this.totalCheckins == 0 ?
                        <div>
                            {UsersGrid.Resources.noCheckinsYet}
                        </div>
                        : <div>
                            {
                                this.currentMonthCheckins == 0 ?
                                    <div>
                                        <p>{UsersGrid.Resources.noCheckinsThisMonth}</p>
                                        <p>{String_Format(UsersGrid.Resources.totalCheckins, this.totalCheckins)}</p>
                                    </div>
                                    : <div>
                                        <p>{String_Format(UsersGrid.Resources.checkinsThisMonth, this.currentMonthCheckins)}</p>
                                        <p>{String_Format(UsersGrid.Resources.totalCheckins, this.totalCheckins)}</p>
                                    </div>
                            }
                        </div>
                }
            </div>);

        this.ForProperty(p => "createdAt")
            .SetDisplayName(UsersGrid.Resources.createAtHeaderLabel)
            .Show(_self => <div className="column-medium">
                <p>{formatDate(this.createdAt)}</p>
            </div>);
    }
}